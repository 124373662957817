import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public sessionService: SessionService, public router: Router) { }

  canActivate() : boolean {
    return this.sessionService.isAuthenticated();
  }
}
