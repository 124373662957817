import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavController } from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';
import { ToolsService } from 'src/app/services/tools/tools.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  back: boolean = false;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private sessionService: SessionService,
    private navCtrl: NavController,
    private router: Router,
    private toolsService: ToolsService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.initializeApp();
    this.backButtonActions();
    this.platformCheck();
  }

  public backButtonActions() {
    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        this.platform.backButton.subscribeWithPriority(-1, () => {
          console.log('event backButton');
          console.log(this.router.url);

          if (this.router.url.search('tabs/edit') !== -1 || this.router.url.search('update-password') !== -1) {
            this.router.navigate(['tabs/profile']);
          }

          if (this.router.url.search('tabs/detailWallet') !== -1 ||
              this.router.url.search('tabs/verify') !== -1 ||
              this.router.url.search('refer-third') !== 1 || this.router.url.search('recharge') !== 1) {
            this.router.navigate(['tabs/home']);
          }

          if (this.router.url.search('signup') !== -1 || this.router.url.search('reset-password') !== -1) {
            this.router.navigate(['login']);
          }

          if (this.router.url.search('home') !== -1 || this.router.url.search('profile') !== -1) {
            if (!this.back) {
              this.back = true;
              this.toolsService.actionConfirmTwo('Información', '¿Quieres Salir de la App?', ()=> {
                navigator['app'].exitApp();
              }, 'Salir', () => {
                this.back = false;
              });
            }
          }
        });
      }
    });
  }

 private initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#248257');
      this.splashScreen.hide();
      this.inicializeUser();
    });
  }

  private inicializeUser() {
    this.sessionService.inicializeUser().then( () => {
      if (!(this.platform.is('mobileweb') || this.platform.is('desktop'))) {
        this.navCtrl.navigateRoot('tabs');
      }
    }, () => {
        // this.navCtrl.navigateRoot('login');
    });
  }

  private platformCheck() {
    this.platform.ready().then(() => {
        this.platform.pause.subscribe(() => {
            console.log('[INFO] App paused');
        });

        this.platform.resume.subscribe(() => {
            console.log('[INFO] App resumed');
            if (this.router.url.search('login') === -1
                && this.router.url.search('signup') === -1
                && this.router.url.search('reset-password')) {
              this.sessionService.setDate();
            }
        });
    });
  }
}
