import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(
    public loadingController: LoadingController,
    public alertController: AlertController,
    public platform: Platform,
    public fingerprintAIO: FingerprintAIO
  ) { }

  async presentLoading(text = 'Espere por favor!') {
    return  await this.loadingController.create({
      message: text,
      cssClass: 'my-custom-class',
    });
  }

  async fingerPrintIsAvailable() {
    try {
      await this.platform.ready();
      const isIos = this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone');
      const isAndroid = this.platform.is('android') || this.platform.is('tablet');
      return isIos || isAndroid ? await this.fingerprintAIO.isAvailable() : false;
    } catch (e) {
      return false;
    }
  }

  async fingerprintAuth() {
    try {
      await this.platform.ready();
      const isIos = this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone');
      const isAndroid = this.platform.is('android') || this.platform.is('tablet');
      if (isIos || isAndroid) {
        if (!await this.fingerprintAIO.isAvailable()) {
          console.log('Fingerprint is not available');
          return { authenticate: false, data: 'No disponible' };
        }

        const params = {
          clientId: 'Fingerprint-Garzon',
          clientSecret: 'password', //Only necessary for Android
          disableBackup: true, //Only for Android(optional)
          localizedFallbackTitle: 'Use Pin', //Only for iOS
          localizedReason: 'Please authenticate' //Only for iOS
        }

        let result = await this.fingerprintAIO.show(params);
        console.log(':::result ', isIos ? 'IOS' : 'Android', result);
        return {authenticate: true, data: result};
      }
    } catch (e) {
      console.log('fingerprintAuth ', e);
      return { authenticate: false, data: e };
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async actionConfirm(title, message, success, titleSuccess) {
    const confirm = await this.alertController.create({
      header: title,
      message: message,
      buttons: [
        {
          text: titleSuccess,
          handler: success
        }
      ],
      backdropDismiss: false
    });

    await confirm.present();
  }

  async actionConfirmTwo(title, message, success, titleSuccess, cancel) {
    const confirm = await this.alertController.create({
      header: title,
      message: message,
      buttons: [
        {
          text: titleSuccess,
          handler: success
        },
        {
          text: 'NO',
          handler: cancel
        }
      ],
      backdropDismiss: false
    });

    await confirm.present();
  }

  /**
 * formatDisplay(value, n, x, s, c)
 *
 * @param integer value: number
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 * output: (12345678.9, 2, 3, '.', ',');  --> "$ 12.345.678,90"
 * output (123456.789, 4, 4, ' ', ':');   --> "$ 12 3456:7890"
 * output (12345678.9, 3, '-');           --> "$ 12-345-679"
 */
  formatDisplayValue(value, n, x, s, c) {
    value = typeof(value) == 'number' ? value : Number(value);
    if (Number.isNaN(value)) {
      value = 0;
    }
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = value.toFixed(Math.max(0, ~~n));

    return ` ${(c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))}`;
  }

  async showAlert(title, content) {
    const alert = await this.alertController.create({
      header: title,
      message: content,
      buttons: ['OK'],
      cssClass: 'custom-list-alert-controller'
    });

    await alert.present();
  }


  async verifyPassword(fun) {
    const alert = await this.alertController.create({
      header: 'Verificar',
      cssClass: 'modal-custom-css',
      inputs: [
        {
          name: 'password',
          placeholder: 'Contraseña',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {}
        },
        {
          text: 'Comprobar',
          handler: data => {
            fun(data);
          }
        }
      ]
    });

    await alert.present();
  }

  public objectHeader(client, wallet, type, typeView, ref = null) {
    return {
      "encabezados": {
        "id_number": `${client.id_number}` ,
        "name": `${client.firstname}`,
        "email": `${client.email}`,
        "mobile": `${client.mobile}`,
        "user": client['user'] ? client.user : null
      },
      "detalles": [
        {
          "id":null,
          "wallet_id":null,
          "moneda": wallet.typeMoney.moneda,
          "cliente_id":null,
          "factor":0,
          "monto": wallet.total,
          "localidad": typeView == 3 ? 998 : 999,
          "nro_fact":"0",
          "caja": "0",
          "tipotrans": type,
          "descriptrans": type == 0 ? typeView == 3 ? 'RECARGA' : "INGRESO TRANSFERENCIA" : "TRANSFERENCIA A TERCERO",
          "token": "",
          "trans": true,
          "codigo_ref": ref
        }
      ]
    };
  }

  public objectHeaderTransfer({clientThird, user, wallet, typeView, ref = null}) {
    // RECARGA - LOCALIDAD: 998
    // TRANSFERENCIA A TERCERO - LOCALIDAD: 999
    return {
      "detalles": [
        {
          "id": null,
          "wallet_id": null,
          "moneda": wallet.typeMoney.moneda,
          "cliente_id":null,
          "factor":0,
          "monto": wallet.total,
          "localidad": typeView == 3 ? 998 : 999,
          "nro_fact":"0",
          "caja": "0",
          "tipotrans": 1,
          "descriptrans": "SALIDA TRANSFERENCIA A TERCERO",
          "token": "",
          "trans": true,
          "codigo_ref": ref,
          "encabezados": {
            "id_number": `${user.id_number}` ,
            "name": `${user.firstname}`,
            "email": `${user.email}`,
            "mobile": `${user.mobile}`,
            "user": user['user'] ? user.user : null
          }
        },
        {
          "id":null,
          "wallet_id": null,
          "moneda": wallet.typeMoney.moneda,
          "cliente_id": null,
          "factor":0,
          "monto": wallet.total,
          "localidad": typeView == 3 ? 998 : 999,
          "nro_fact":"0",
          "caja": "0",
          "tipotrans": 0,
          "descriptrans": "INGRESO TRANSFERENCIA DESDE TERCERO",
          "token": "",
          "trans": true,
          "codigo_ref": ref,
          "encabezados": {
            "id_number": `${clientThird.id_number}` ,
            "name": `${clientThird.firstname}`,
            "email": `${clientThird.email}`,
            "mobile": `${clientThird.mobile}`,
            "user": clientThird['user'] ? clientThird.user : null
          }
        }
      ]
    };
  }

  public msgResponse(value) {
    let msg;
    switch (value) {
      case 200:
        msg = 'Proceso Exitoso';
        break;
      case 204:
        msg = 'Formato incorrecto';
        break;
      case 205:
        msg = 'Error decodificando la cadena';
        break;
      case 1000:
        msg = 'Error al buscar el cliente';
        break;
      case 1001:
        msg = 'Error al crear el cliente';
        break;
      case 2000:
        msg = 'No se envio el arreglo de detalles';
        break;
      case 2001:
        msg = 'Error al crear monedero';
        break;
      case 2002:
        msg = 'Error al crear detalle de monedero';
        break;
      case 2003:
        msg = 'Error al actualizar monedero';
        break;
      case 3000:
        msg = 'Token caducó';
        break;
      case 3001:
        msg = 'Token no coincide';
        break;
      case 3002:
        msg = 'Fondos insuficiente';
        break;
      case 4000:
        msg = 'Localidad no existe';
        break;
      case 5000:
        msg = 'Moneda no existe';
        break;
      default:
        msg = 'Error inesperado';
    }
    return msg;
  }
}
